import {Link} from "react-router-dom";
import {useEffect} from "react";

export const BurgerMenu = () => {

    useEffect(()=>{
        const menu = document.getElementById('menuButton'),
            show = document.getElementById('showMenu');

        const displayed = () => {
            show.classList.toggle('displayed')
        }

        menu.addEventListener('click', displayed);
        show.addEventListener('click',displayed)


    })

    return <>
        <div className="block md:hidden">
            <button id="menuButton" className="font-bold text-xl text-analco-brown-500 outline-none">Menu</button>
        </div>
        <nav id="showMenu" className="burger-menu">
            <Link to="/" className=" block font-bold uppercase text-lg px-4 text-analco-brown-500 hover:bg-analco-orange-300 py-2">Inicio</Link>
            <Link to="/nosotros" className=" block font-bold uppercase text-lg px-4 text-analco-brown-500 hover:bg-analco-orange-300 py-2">Sobre Nosotros</Link>
            <Link to="/recetas" className="block font-bold uppercase text-lg px-4 text-analco-brown-500 hover:bg-analco-orange-300 py-2">Recetas</Link>
            <Link to="/encuentranos" className="block font-bold uppercase text-lg px-4 text-analco-brown-500 hover:bg-analco-orange-300 py-2">Encuéntranos</Link>
            <Link to="/contacto" className="block font-bold uppercase text-lg px-4 text-analco-brown-500 hover:bg-analco-orange-300 py-2">Contacto</Link>
        </nav>
    </>
}
