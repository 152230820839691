import {BoutUsCards} from "../Components/BoutUsCards/BoutUsCards";
import {SectionTitle} from "../Helpers/Titles";
import {TitleBanner} from "../Helpers/Titles";

export const Nosotros = () => {


    return <>
        <section className="container mx-auto">
            <TitleBanner>
                Sobre nosotros
            </TitleBanner>
            <SectionTitle>
                Nuestra filosofía
            </SectionTitle>
            <BoutUsCards/>
        </section>
    </>
}
