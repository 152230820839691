export const Frase = ({children})=>{

    return <>
        <div className="w-full my-5" style={{
            height: '200px',
            'backgroundSize': 'cover',
            'backgroundPosition': 'center',
        }}>
            <div className="w-full h-full p-2" style={{
                'background': '#78363188',
                'display': 'flex',
                'justifyContent': 'center',
                'alignItems': 'center',
                'blendMode': 'multiply',
            }}>
                <p className="text-2xl text-white font-extrabold text-center uppercase">{children}</p>
            </div>

        </div>
    </>
}
