import {SectionTitle, CardTitle} from "../../Helpers/Titles";

import fb from '../../statics/fb.svg';
import ig from '../../statics/ig.svg';
import mail from '../../statics/gmail.svg';
import phone from '../../statics/phone-call.svg'

export const Contacts = ()=> {
    return <>
        <section className="mx-auto container bg-white py-5 mt-5 mb-5 rounded-lg">
            <div>
                <SectionTitle>
                    Escríbenos
                </SectionTitle>
            </div>
            <div>
                <div className="grid grid-cols-3 gap-5">
                    <div>
                        <a href="https://facebook.com/moledeanalco" rel="noreferrer" target="_blank">
                        <div>
                            <img src={fb} alt="facebook" className="p-3 bg-analco-orange-500 rounded-full w-16 md:w-24 border-4 border-analco-brown-500 mx-auto"/>
                        </div>
                        <CardTitle>
                            Facebook
                        </CardTitle>
                        </a>
                    </div>
                    <div>
                        <a href="https://instagram.com/moledeanalco" rel="noreferrer" target="_blank">
                            <div>
                                <img src={ig} alt="instagram" className="p-3 bg-analco-orange-500 rounded-full w-16 md:w-24 border-4 border-analco-brown-500 mx-auto"/>
                            </div>
                            <CardTitle>
                                Instagram
                            </CardTitle>
                        </a>
                    </div>
                    <div>
                        <a href="mailto:ventas@deanalco.com" rel="noreferrer" target="_blank">
                            <div>
                                <img src={mail} alt="email" className="p-3 bg-analco-orange-500 rounded-full w-16 md:w-24 border-4 border-analco-brown-500 mx-auto"/>
                            </div>
                            <CardTitle>
                                Email
                            </CardTitle>
                        </a>
                    </div>
                </div>
                <div className="mt-5">
                    <SectionTitle>
                        Visítanos
                    </SectionTitle>
                    <iframe
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3644.014518301347!2d-104.66400738501333!3d24.0305531844521!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x869bc8212b01f82f%3A0x1866d8920d0af1eb!2sDe%20Analco!5e0!3m2!1sen!2smx!4v1624158547636!5m2!1sen!2smx"
                        className="w-full h-80"  title="ubicacion" loading="lazy"> </iframe>
                </div>
                <div className="mt-8">
                    <SectionTitle>
                        Llámanos
                    </SectionTitle>
                    <a href="tel:6188293462" rel="noreferrer" target="_blank" className="flex items-center justify-center">
                        <div>
                            <img src={phone} alt="email" className="p-1 bg-analco-orange-500 rounded-full mr-3 w-12 border-4 border-analco-brown-500 mx-auto"/>
                        </div>
                        <CardTitle>
                            618 829 3462
                        </CardTitle>
                    </a>
                </div>
            </div>
        </section>
    </>
}
