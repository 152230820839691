import {CardTitle,SectionTitle} from "../../Helpers/Titles";

import walmart from '../../statics/brands/walmart.png';
import soriana from '../../statics/brands/Soriana.png';
import alsuper from '../../statics/brands/Alsuper.png';
import sumesa from '../../statics/brands/SUMESA.png';
import chedrahui from '../../statics/brands/Chedrahui.png';
import canastos from '../../statics/brands/Canastos.png';
import ley from '../../statics/brands/Ley.png';
import huerta from '../../statics/brands/LaHuerta.png';
import oxxo from '../../statics/brands/Oxxo.png';
import bodega from '../../statics/brands/BodegaAurrera.png';

const ciudades = {
    durango: [
        alsuper,
        soriana,
        walmart,
        bodega,
        chedrahui,
        ley,
        canastos,
        oxxo,
    ],
    santiago: [
        sumesa,
        huerta
    ],
    gomez: [
        alsuper,
        soriana,
    ],
    lerdo: [
        alsuper,
    ],
    chihuahua: [
        alsuper,
    ],
    monterrey: [
        walmart,
    ],
    saltillo: [
        alsuper,
    ],
    torreon: [
        alsuper,
        soriana,
        walmart
    ]
}
export const MarcasCiudades = () => {

    return <>
        <section className="mx-4 md:mx-auto md:container bg-white pt-4 px-2 mt-5 mb-5 rounded-lg">
            <SectionTitle>
                Ciudades donde puedes encontrar mole de analco
            </SectionTitle>
            <div className="pt-5">
                <CardTitle>
                    Victoria de Durango, Dgo.
                </CardTitle>
                <div className="flex flex-wrap justify-center px-4">
                    {
                        ciudades.durango.map((store, key) =>{
                            return <img src={store} key={key} alt="logo" className="w-20 md:w-36"/>
                        })
                    }
                </div>
            </div>
            <div className="pt-5">
                <CardTitle>
                    Gómez Palacio, Dgo.
                </CardTitle>
                <div className="flex flex-wrap justify-center px-4">
                    {
                        ciudades.gomez.map((store, key) =>{
                            return <img src={store} key={key} alt="logo" className="w-20 md:w-36"/>
                        })
                    }
                </div>
            </div>
            <div className="pt-5">
                <CardTitle>
                    Ciudad Lerdo, Dgo.
                </CardTitle>
                <div className="flex flex-wrap justify-center px-4">
                    {
                        ciudades.lerdo.map((store, key) =>{
                            return <img src={store} key={key} alt="logo" className="w-20 md:w-36"/>
                        })
                    }
                </div>
            </div>
            <div className="pt-5">
                <CardTitle>
                    Santiago Papasquiaro, Dgo
                </CardTitle>
                <div className="flex flex-wrap justify-center px-4">
                    {
                        ciudades.santiago.map((store, key) =>{
                            return <img src={store} key={key} alt="logo" className="w-20 md:w-36"/>
                        })
                    }
                </div>
            </div>
            <div className="pt-5">
                <CardTitle>
                    Chihuahua, Chi.
                </CardTitle>
                <div className="flex flex-wrap justify-center px-4">
                    {
                        ciudades.chihuahua.map((store, key) =>{
                            return <img src={store} key={key} alt="logo" className="w-20 md:w-36"/>
                        })
                    }
                </div>
            </div>
            <div className="pt-5">
                <CardTitle>
                    Monterrey, N.L.
                </CardTitle>
                <div className="flex flex-wrap justify-center px-4">
                    {
                        ciudades.monterrey.map((store, key) =>{
                            return <img src={store} key={key} alt="logo" className="w-20 md:w-36"/>
                        })
                    }
                </div>
            </div>
            <div className="pt-5">
                <CardTitle>
                    Saltillo, Coah.
                </CardTitle>
                <div className="flex flex-wrap justify-center px-4">
                    {
                        ciudades.saltillo.map((store, key) =>{
                            return <img src={store} key={key} alt="logo" className="w-20 md:w-36"/>
                        })
                    }
                </div>
            </div>
            <div className="pt-5">
                <CardTitle>
                    Torreon, Coah.
                </CardTitle>
                <div className="flex flex-wrap justify-center px-4">
                    {
                        ciudades.torreon.map((store, key) =>{
                            return <img src={store} key={key} alt="logo" className="w-20 md:w-36"/>
                        })
                    }
                </div>
            </div>

        </section>
    </>
}
