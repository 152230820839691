import {TitleBanner} from "../Helpers/Titles";
import {Contacts} from "../Components/Contact/Contacts";

export const Contacto = () => {

    return <>
        <section className="mx-auto container">
            <TitleBanner>
                Contáctanos
            </TitleBanner>
             <Contacts/>
        </section>
    </>
}
