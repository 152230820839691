import logo from '../../statics/logo.svg'

import {BoutUsCard} from "./BoutUsCard";

export const BoutUsCards = () => {

    return <>
        <section className="md:grid grid-cols-3 gap-4">
            <BoutUsCard img={logo} title={"Misión"}>
                Resolver la necesidad de tener un platillo rápido, rico y versátil para los hogares mexicanos.
            </BoutUsCard>
            <BoutUsCard img={logo} title={"Visión"}>
                Convertirnos en la empresa número uno de la región, estandarizando nuestros procesos y mejorando el servicio al cliente, posteriormente lograr la exportación del platillo típico mexicano (mole) a otros países para dar a conocer el “sabor de México”.
            </BoutUsCard>
            <BoutUsCard img={logo} title={"Valores"}>
                Somos una empresa que mantiene el respeto con nuestros clientes como con los compañeros de trabajo. Somos honestos, responsables y disciplinados, enfocados a mejorar los resultados día con día.
            </BoutUsCard>
        </section>
    </>
}
