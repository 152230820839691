import {Banners} from "../Components/Banners/Banners";
import {HomeBrands} from "../Components/Brands/HomeBrands";
import {Frase} from "../Components/Frase/Frase";

export const Home = () => {

    return <>
        <section className="mx-auto container">
        <Banners/>
        <HomeBrands/>
        <Frase>
            Nos esforzamos día con día para traerte el mejor mole, <br className="hidden md:block"/> con ingredientes de la más alta calidad
        </Frase>
        </section>
    </>
}
