import logo from '../../statics/logo.svg';
import place from '../../statics/placeholder.svg';
import fb from '../../statics/fb.svg'
import ig from '../../statics/ig.svg'
import phone from '../../statics/phone-call.svg'
import {Link} from 'react-router-dom';

export const Footer = () => {

    const cdate = new Date(),
        currentYear = cdate.getFullYear()

    return <footer className="bg-analco-brown-500 border-t-8 border-analco-orange-500">
        <section className="md:grid grid-cols-4 gap-4 container mx-auto py-3">
            <div>
                <h3 className="text-center md:text-left text-white font-bold text-lg uppercase">Mapa del sitio</h3>
                <Link to="/" className="text-white block text-center md:text-left py-1.5 md:py-1">Inicio</Link>
                <Link to="/nosotros" className="text-white block text-center md:text-left py-1.5 md:py-1">Sobre nosotros</Link>
                <Link to="/recetas" className="text-white block text-center md:text-left py-1.5 md:py-1">Recetas</Link>
                <Link to="/encuentranos" className="text-white block text-center md:text-left py-1.5 md:py-1">Encuéntranos</Link>
                <Link to="/contacto" className="text-white block text-center md:text-left py-1.5 md:py-1">Contacto</Link>
            </div>
            <div>
                <h3 className=" text-center md:text-left text-white font-bold text-lg uppercase">Redes sociales</h3>
                <div>
                    <a href="https://facebook.com/moledeanalco" target="_blank" rel="noreferrer" className="flex justify-center md:justify-start items-center py-1.5 md:py-1"><img src={fb} alt="facebook" className="w-4 inline-block mr-1"/><p className="text-white text-md ">Facebook</p></a>
                </div>
                <div>
                    <a href="https://instagram.com/moledeanalco" target="_blank" rel="noreferrer"  className="flex justify-center md:justify-start items-center py-1.5 md:py-1"><img src={ig} alt="instagram" className="w-4 inline-block mr-1"/><p className="text-white text-md ">Instagram</p></a>
                </div>
                <h3 className="text-white font-bold text-lg uppercase mt-3 text-center md:text-left ">Llámanos</h3>
                <div>
                    <a href="tel:6188293462" target="_blank" rel="noreferrer" className="flex justify-center md:justify-start items-center py-1.5 md:py-1"><img src={phone} alt="Llamada" className="w-4 inline-block mr-1"/><p className="text-white text-md ">6188293462</p></a>
                </div>

            </div>
            <div className="p-2">
                <img src={place} alt="ublicación" className="w-8 mx-auto md:mx-0"/>
                <address className="text-white text-center md:text-left ">
                    Calle Miguel de Cervantes #240 <br/>
                    Zona Centro. CP 34000 <br/>
                    Victoria de Durango, Dgo. Mx.
                </address>

            </div>
            <div className="p-2">
                <img src={logo} alt="logotipo mole de analco" className="w-24 mx-auto md:mx-0"/>
                <p className="text-white italic font-bold mt-2 text-sm text-center md:text-left ">Orgullosamente mexicano. <br/> Orgullosamente duranguense</p>
            </div>
        </section>
        <div className="bg-analco-brown-700">
        <div className="mx-auto container text-center text-white p-1">
            Mole de Analco. <br/> Todos los derechos reservados {currentYear}
        </div>
        </div>
    </footer>
}
