import { CardTitle } from "../../Helpers/Titles";
import { Link } from "react-router-dom";

import walmart from '../../statics/brands/walmart.png';
import soriana from '../../statics/brands/Soriana.png';
import alsuper from '../../statics/brands/Alsuper.png';
import sumesa from '../../statics/brands/SUMESA.png';
import chedrahui from '../../statics/brands/Chedrahui.png';
import canastos from '../../statics/brands/Canastos.png';
import ley from '../../statics/brands/Ley.png';
import huerta from '../../statics/brands/LaHuerta.png';
import oxxo from '../../statics/brands/Oxxo.png';
import bodega from '../../statics/brands/BodegaAurrera.png';

const brands = [
    walmart,
    soriana,
    alsuper,
    sumesa,
    chedrahui,
    canastos,
    ley,
    huerta,
    oxxo,
    bodega
]

export const MarcasHome = () => {

    return <>
        <section className="mx-auto container bg-white rounded-lg p-5">
            <CardTitle>
                Estamos en tu supermercado y tiendas de conveniencias
            </CardTitle>
            <div className="flex flex-wrap justify-center ">
                {
                    brands.map((image,index)=> {
                        return <img key={index} src={image} alt="logo" className="rounded-lg bg-white mx-2 object-cover my-2 w-24"/>
                    })
                }
            </div>
            <div className="flex justify-center mt-5">
             <Link to="/encuentranos" className="px-5 py-2 bg-analco-orange-500 text-lg font-bold text-white rounded-md">Conoce tu tienda más cercana</Link>
            </div>
        </section>
    </>
}
