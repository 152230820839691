import {TitleBanner} from "../Helpers/Titles";
import {MarcasCiudades} from "../Components/Marcas/MarcasCiudades";

export const Encuentranos = () => {

    return <>
        <section className="container mx-auto">
            <TitleBanner>
                Encuéntranos
            </TitleBanner>
            <MarcasCiudades/>
        </section>
    </>
}
