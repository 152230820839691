import styled from "styled-components";
import banner1 from '../../statics/banner0.png';
import banner2 from '../../statics/banner1.png';
import banner3 from '../../statics/banner2.png';
import {useEffect} from "react";

const StyledBanners = styled.div`
  width: 100%;
  margin-top: 20px;
  overflow: hidden;
  border-radius: 10px;
`

const StyledBanner = styled.div`
  min-width: 100%;
  min-height: 100%;
  height: 100%;
`



export const Banners = () => {

    useEffect(()=>{
        const slideContainer = document.getElementById('slide-container'),
            sliderChildren = slideContainer.children;
            slideContainer.style.left = '-100%';

        setInterval(()=>{
            const firstElementCarrusel = sliderChildren[0];
            slideContainer.style.transition = 'all .5s'
            slideContainer.style.left = "-200%";
            setTimeout(()=>{
                slideContainer.style.transition = 'none';
                slideContainer.insertAdjacentElement('beforeend', firstElementCarrusel);
                slideContainer.style.left = `-100%`;
            },500)
        },7000)


    })

    return <>
        <div className="container mx-auto">
        <StyledBanners>
            <div className="flex no-wrap relative" id="slide-container">
            <StyledBanner>
                <img src={banner1} className="h-full w-full object-cover"  alt=""/>
            </StyledBanner>
            <StyledBanner>
                <img src={banner2} className="h-full w-full object-cover"  alt=""/>
            </StyledBanner>
            <StyledBanner>
                <img src={banner3} className="h-full w-full object-cover"  alt=""/>
            </StyledBanner>
            </div>
        </StyledBanners>
        </div>
    </>
}
