import styled from "styled-components";
import img from '../statics/mole.jpg';

export const SectionTitle = ({children}) => {
    return <>
        <div className="my-5 flex justify-center">
            <h2 className="text-xl text-center text-white font-bold uppercase bg-analco-brown-500 inline-block px-4 py-2 rounded-md">{children}</h2>
        </div>
    </>
}

export const CardTitle = ({children}) =>{

    return <>
        <div>
            <h3 className="text-xl text-center text-analco-brown-500 font-bold uppercase">{children}</h3>
        </div>
    </>
}

//Banner con título
const TitledBanner = styled.div`
      width: 100%;
      background-image: url(${img});
 
      background-size: cover;
      background-position: center;
      min-height: 150px;
      object-fit: cover;
      border-radius: 10px;
      overflow: hidden;
      margin-top: 20px;
      box-sizing: border-box;`

const Shade = styled.div`
      width: 100%;
      min-height: 150px;
      background: #783631dd;
      backdrop-filter: grayscale(100%);
      display: flex;
      align-items: center;
      justify-content: center;`

export const TitleBanner = ({children, image}) => {

    return <>
        <div className="mx-4 md:mx-0">
        <TitledBanner>
            <Shade>
                <h2 className="text-white text-4xl font-bold">{children}</h2>
            </Shade>
        </TitledBanner>
        </div>
    </>
}
