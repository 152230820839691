import {BrowserRouter as Router, Switch, Route} from 'react-router-dom'
import {Contacto} from "./Pages/Contacto";
import {Encuentranos} from "./Pages/Encuentranos";
import {Home} from "./Pages/Home";
import {Nosotros} from "./Pages/Nosotros";
import {Recetas} from "./Pages/Recetas";
import {NotFound} from "./Pages/NotFound";
import {NavBar} from "./Components/NavBar/NavBar";
import {Footer} from "./Components/Footer/Footer";

export const App = () => {

    return <>
        <Router>
            <NavBar/>
            <Switch>
                <Route path="/contacto">
                    <Contacto/>
                </Route>
                <Route path="/encuentranos">
                    <Encuentranos/>
                </Route>
                <Route path="/nosotros">
                    <Nosotros/>
                </Route>
                <Route path="/recetas">
                    <Recetas/>
                </Route>
                <Route exact path="/">
                    <Home/>
                </Route>
                <Route path="*">
                    <NotFound/>
                </Route>
            </Switch>
            <Footer/>
        </Router>
    </>
}
