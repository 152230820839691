import {CardTitle} from "../../Helpers/Titles";

export const BoutUsCard = ({img, title, children}) => {

    return <>
        <article className="p-4 bg-analco-orange-300 rounded-md my-4 md:my-5 mx-4 md:mx-0">
            <figure>
                <img src={img} alt="" className="w-32 mx-auto mb-2"/>
            </figure>
            <CardTitle>
                {title}
            </CardTitle>
            <div>
                <p className="text-center text-analco-brown-500 font-medium mt-2">{children}</p>
            </div>
        </article>
    </>
}
