import {TitleBanner, SectionTitle} from "../Helpers/Titles";
import {RecetasCard} from "../Components/RecetasCard/RecetasCard";
import tamales from '../statics/Recetatamales.jpg';
import tamalesReceta from '../statics/Tamales.pdf'
import costillas from '../statics/recetacostillas.jpg'
import costillasReceta from '../statics/Costillas.pdf'

export const Recetas = () => {

    return <>
        <section className="container mx-auto">
            <TitleBanner>
                Recetas
            </TitleBanner>
            <SectionTitle>
                Para disfrutar en familia
            </SectionTitle>
            <RecetasCard image={tamales} el="Tamales de pollo" download={tamalesReceta}>
                <h4 className="font-bold text-lg text-analco-orange-500 mt-2">Ingredientes:</h4>
                <li>Un pollo entero</li>
                <li>Un sobre de Mole de Analco almendrado</li>
                <li>Sal al gusto</li>
                <li>1 diente de ajo</li>
                <li>¼ cebolla</li>
                <li>Agua</li>
                <h4 className="font-bold text-lg text-analco-orange-500 mt-2">Elaboración:</h4>
                <p>Poner a cocer en una cacerola con agua y a fuego medio el pollo, agregar el ajo, cebolla y sal al gusto para sazonar. Esto tarda 40 minutos aproximadamente.
                Una vez que el pollo esté listo, retirar del fuego y desmenuzar... <br/>
                </p>
            </RecetasCard>
            <RecetasCard image={costillas} download={costillasReceta} el="Costillas de Res">
                <h4 className="font-bold text-lg text-analco-orange-500 mt-2">Ingredientes:</h4>
                <li>1 kilo de costillas de res</li>
                <li>1 sobre de Mole de Analco almendrado</li>
                <li>Sal al gusto</li>
                <li>Pimienta al gusto</li>
                <li>Comino al gusto</li>
                <li>Ajo en polvo al gusto</li>
                <h4 className="font-bold text-lg text-analco-orange-500 mt-2">Elaboración:</h4>
                <p>Colocar en una cacerola las costillas con un poco de aceite con sal, comino, pimienta y ajo en polvo al gusto.
                    Cocer las costillas hasta que la carne este en el punto deseado. Agregar un sobre de Mole de Analco... <br/>
                </p>
            </RecetasCard>
        </section>
    </>
}
