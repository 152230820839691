import {SectionTitle} from "../../Helpers/Titles";
import {MarcasHome} from "../Marcas/MarcasHome";

export const HomeBrands = () => {

    return <>
        <section className="container mx-auto">
            <SectionTitle>
                Encuéntranos en
            </SectionTitle>
            <MarcasHome/>
        </section>
    </>
}
