import { Link } from 'react-router-dom';
import logo from '../../statics/logo.svg';
import {BurgerMenu} from "./BurgerMenu";

export const NavBar = () => {

    return <>
        <header className="md:pt-4 md:mx-4 z-30 sticky top-0 md:static">
            <div className="flex justify-between items-center container mx-auto bg-analco-orange-500 px-2 py-1 md:rounded-md">
                <Link to="/"><img src={logo} alt="logotipo mole de analco" className="w-24"/></Link>
                <BurgerMenu/>
                <nav className="md:flex hidden">
                    <Link to="/nosotros" className=" block font-bold uppercase text-lg px-4 text-analco-brown-500 hover:bg-analco-orange-300 py-2">Sobre Nosotros</Link>
                    <Link to="/recetas" className="block font-bold uppercase text-lg px-4 text-analco-brown-500 hover:bg-analco-orange-300 py-2">Recetas</Link>
                    <Link to="/encuentranos" className="block font-bold uppercase text-lg px-4 text-analco-brown-500 hover:bg-analco-orange-300 py-2">Encuéntranos</Link>
                    <Link to="/contacto" className="block font-bold uppercase text-lg px-4 text-analco-brown-500 hover:bg-analco-orange-300 py-2">Contacto</Link>
                </nav>
            </div>
        </header>
    </>
}
