export const RecetasCard = ({image, el, children, download}) => {

    return <>
        <article className="md:grid grid-cols-3 gap-4 rounded-lg overflow-hidden bg-white mb-4 mx-4 md:mx-0">
            <img src={image} alt="" className="border-b-4 md:border-b-0 md:border-r-4 border-analco-orange-500 object-cover object-right min-h-full"/>
            <section className="col-span-2 p-4 md:p-2">
                <h3 className="font-bold text-2xl text-analco-brown-500">{el}</h3>
                {children}
                <div className="md:block flex justify-center">
                <a className="p-4 bg-analco-orange-500 mt-5 inline-block rounded-lg hover:bg-analco-orange-600 hover:text-white mx-auto" href={download} target="_blank" rel="noreferrer">Descargar receta</a>
                </div>
            </section>
        </article>
    </>
}

